import React from "react";
import '../../../style/Pages/Mountains.css';

export default function ExploreHeader() {
    return(
        <div className="header-content">
            <div className="header-sentence">
                <p>Ski all over the world.</p>
            </div>
        </div>
    );
}