import React from "react";
import '../../../style/MultiPage/Footer.css';

export default function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-content">
                <div className="svg">
                    <p id="footer-title"><svg xmlns="http://www.w3.org/2000/svg" width="155px" height="170px" viewBox="110 270 200 10" version="1.1"><path xmlns="http://www.w3.org/2000/svg" id="path0" d="M175.774 173.241 C 174.230 175.023,170.970 178.231,168.530 180.370 
                    L 164.093 184.259 161.769 189.397 C 160.034 193.232,159.139 194.769,158.241 195.459 C 157.035 196.386,156.566 198.184,157.397 198.697 C 157.948 199.038,159.908 197.896,162.932 195.472 C 164.442 194.261,
                    166.067 193.015,166.543 192.704 C 167.655 191.975,167.637 191.553,166.481 191.263 C 165.051 190.904,165.340 190.297,168.254 187.547 C 176.101 180.139,177.827 179.105,174.815 183.616 C 173.656 185.351,
                    173.333 186.183,173.333 187.439 C 173.333 188.837,173.043 189.417,171.076 191.956 C 166.863 197.390,170.096 194.925,174.737 189.164 C 176.010 187.584,177.720 185.807,178.537 185.215 L 180.022 184.138 
                    182.867 185.016 C 186.246 186.059,186.166 186.104,185.582 183.492 C 184.936 180.606,185.248 180.648,187.056 183.692 C 188.698 186.456,192.266 190.000,193.407 190.000 C 194.498 190.000,194.183 189.260,
                    192.195 187.159 C 191.056 185.954,189.802 184.059,189.010 182.346 C 188.292 180.791,187.001 178.753,186.141 177.815 C 185.281 176.878,183.775 175.028,182.795 173.704 C 179.371 169.081,179.376 169.082,
                    175.774 173.241 M207.881 174.919 C 207.472 175.371,205.077 178.491,202.559 181.852 C 197.937 188.023,195.202 191.029,190.661 194.927 C 188.700 196.611,187.932 197.588,187.029 199.549 C 184.277 205.528,
                    182.477 207.903,179.755 209.149 C 178.289 209.820,177.483 210.488,176.787 211.609 C 176.262 212.456,174.996 214.398,173.975 215.926 L 172.119 218.704 175.823 215.185 C 177.861 213.250,181.051 210.683,
                    182.912 209.482 C 186.488 207.173,186.619 207.025,185.741 206.296 C 184.855 205.561,184.900 205.499,190.959 199.080 C 197.679 191.960,198.685 191.350,196.667 195.615 C 194.923 199.301,195.269 199.220,
                    199.895 194.864 C 205.082 189.980,205.335 189.961,201.948 194.707 C 197.390 201.091,196.969 201.794,197.224 202.599 C 197.457 203.333,196.221 205.351,192.689 210.000 C 191.993 210.917,194.841 208.169,
                    199.018 203.893 C 203.196 199.618,206.705 196.211,206.817 196.323 C 206.929 196.436,206.258 197.850,205.325 199.467 C 204.392 201.084,203.038 203.491,202.316 204.815 L 201.003 207.222 202.318 205.926 C 
                    203.040 205.213,205.328 202.796,207.402 200.556 L 211.171 196.481 210.918 193.611 C 210.778 192.032,210.768 190.741,210.895 190.741 C 211.396 190.741,213.989 192.581,215.848 194.256 C 218.379 196.536,
                    218.385 196.515,216.631 191.140 C 214.655 185.079,214.896 184.603,218.180 188.090 C 220.333 190.378,220.741 191.015,220.741 192.096 C 220.741 193.855,221.146 194.482,223.363 196.153 C 224.615 197.098,
                    225.512 198.145,225.969 199.197 C 226.860 201.247,226.846 201.478,225.833 201.496 C 225.109 201.509,225.236 201.728,226.809 203.175 C 228.831 205.036,229.235 205.078,228.333 203.333 C 227.447 201.620,
                    227.956 201.514,229.559 203.080 C 231.085 204.569,231.481 204.736,231.481 203.889 C 231.481 203.041,231.866 203.194,234.267 204.996 C 235.485 205.911,236.606 206.661,236.759 206.663 C 238.061 206.681,
                    233.680 201.731,231.204 200.387 C 229.251 199.326,227.910 197.677,224.150 191.715 C 221.335 187.253,219.823 185.413,213.606 178.889 C 208.789 173.834,208.835 173.866,207.881 174.919 M178.667 187.715 C 
                    177.872 188.292,174.745 191.333,171.718 194.474 C 162.719 203.811,160.747 206.595,167.428 200.528 C 173.727 194.810,174.664 194.350,172.613 197.984 C 170.798 201.200,171.154 201.153,175.281 197.630 C 
                    178.020 195.292,178.221 195.588,176.282 199.099 C 175.417 200.667,174.898 201.885,175.127 201.808 C 176.094 201.481,180.388 196.083,181.157 194.227 C 182.169 191.785,182.119 191.788,184.517 194.074 C 
                    186.362 195.834,187.407 196.469,186.803 195.463 C 184.488 191.607,181.016 186.667,180.621 186.667 C 180.341 186.667,179.461 187.138,178.667 187.715 M149.597 199.097 C 148.087 200.657,145.352 203.194,
                    143.519 204.735 C 141.685 206.276,138.435 209.240,136.296 211.323 C 133.396 214.146,132.031 215.212,130.926 215.517 C 128.862 216.086,128.814 216.132,126.875 219.419 C 125.922 221.035,124.346 223.368,
                    123.374 224.605 C 120.570 228.167,120.777 228.193,124.309 224.722 C 126.123 222.940,127.796 221.481,128.027 221.481 C 128.257 221.481,128.765 220.977,129.155 220.361 C 129.834 219.288,136.015 214.444,
                    136.705 214.444 C 138.473 214.444,135.835 219.534,132.308 222.926 C 128.574 226.517,128.798 226.431,133.889 222.319 C 140.357 217.094,141.490 215.962,140.278 215.943 C 138.116 215.907,139.850 213.599,
                    146.167 208.100 C 150.608 204.233,150.825 204.199,149.281 207.604 C 148.626 209.048,148.148 210.724,148.148 211.576 C 148.148 212.657,147.819 213.506,146.911 214.765 C 144.394 218.255,144.679 218.273,
                    148.293 214.853 C 152.054 211.293,155.569 209.523,154.188 211.885 C 152.717 214.399,149.158 217.760,143.519 221.961 C 140.259 224.389,137.426 226.546,137.222 226.753 C 136.328 227.664,149.523 218.655,
                    153.804 215.431 C 156.406 213.472,158.906 211.674,159.360 211.435 C 161.094 210.522,161.329 207.593,159.835 205.518 C 158.979 204.329,157.534 202.145,155.000 198.208 C 153.371 195.677,152.821 195.767,
                    149.597 199.097 M211.948 198.751 C 211.525 198.933,205.411 206.404,203.963 208.508 C 203.617 209.012,203.333 209.775,203.333 210.204 C 203.333 210.741,202.051 212.072,199.219 214.474 C 193.375 219.429,
                    193.462 219.352,194.074 219.045 C 197.105 217.525,210.230 207.724,211.805 205.805 C 212.622 204.809,213.372 205.053,216.875 207.454 C 225.062 213.065,229.015 215.662,231.898 217.324 C 236.733 220.113,
                    237.905 220.395,233.950 217.818 C 231.264 216.068,230.380 215.201,228.754 212.721 C 227.743 211.178,227.210 210.790,224.630 209.711 C 221.002 208.195,220.223 207.492,219.235 204.846 C 217.927 201.345,
                    217.372 200.409,216.164 199.667 C 214.925 198.905,212.709 198.424,211.948 198.751 M245.768 200.927 C 244.765 201.827,243.508 202.658,242.975 202.775 C 241.097 203.188,239.615 204.460,237.910 207.123 C 
                    236.962 208.604,235.377 210.723,234.389 211.833 C 231.084 215.546,232.360 215.953,236.135 212.389 C 237.548 211.055,239.244 209.720,239.904 209.423 C 240.564 209.125,242.382 207.717,243.945 206.293 C 
                    247.470 203.079,246.438 202.720,253.010 209.444 C 259.866 216.460,261.063 217.068,256.630 211.284 C 254.171 208.076,254.466 207.980,258.401 210.706 C 262.671 213.664,262.963 213.899,262.963 214.366 C 
                    262.963 214.583,262.671 214.820,262.315 214.893 C 260.608 215.243,269.781 220.885,271.920 220.801 C 272.916 220.762,274.168 221.152,275.977 222.063 C 279.351 223.762,279.460 223.555,276.374 221.311 C 
                    273.978 219.569,272.391 218.796,270.316 218.359 C 268.984 218.079,266.259 215.758,265.454 214.220 C 265.093 213.528,264.639 212.963,264.445 212.963 C 264.251 212.963,261.874 210.838,259.164 208.241 C 
                    254.055 203.346,249.000 199.247,248.107 199.276 C 247.824 199.285,246.772 200.028,245.768 200.927 M213.454 210.253 C 212.471 211.004,210.613 212.487,209.325 213.549 C 208.037 214.612,205.999 216.006,
                    204.795 216.647 C 202.070 218.100,200.952 219.036,198.457 221.952 C 196.337 224.430,196.756 224.255,200.014 221.303 C 201.143 220.281,203.143 218.874,204.459 218.177 C 205.775 217.479,208.434 215.854,
                    210.368 214.565 C 214.722 211.663,214.213 211.628,219.691 215.204 L 224.259 218.186 220.833 214.911 C 218.949 213.110,217.407 211.381,217.407 211.068 C 217.407 210.529,215.948 208.889,215.467 208.889 C 
                    215.343 208.889,214.437 209.503,213.454 210.253 M241.481 212.407 C 239.964 213.935,238.806 215.185,238.907 215.185 C 239.009 215.185,240.334 213.935,241.852 212.407 C 243.369 210.880,244.528 209.630,
                    244.426 209.630 C 244.324 209.630,242.999 210.880,241.481 212.407 M159.751 212.157 C 156.803 215.106,153.704 218.863,153.706 219.486 C 153.708 219.871,152.666 221.155,151.391 222.340 C 150.117 223.525,
                    149.324 224.371,149.630 224.219 C 150.840 223.618,155.510 220.345,157.963 218.379 C 161.379 215.640,159.687 215.043,171.009 222.985 C 177.402 227.470,177.640 227.437,172.055 222.836 C 168.460 219.874,168.089 
                    219.442,166.848 216.768 C 165.026 212.844,165.058 212.897,164.045 212.177 C 162.329 210.956,160.959 210.950,159.751 212.157 M243.148 216.296 C 241.068 218.408,241.185 218.642,243.519 217.037 C 245.246 
                    215.849,246.013 215.711,247.661 216.295 C 248.682 216.657,248.677 216.642,247.421 215.555 C 245.699 214.064,245.275 214.137,243.148 216.296 M159.434 221.143 C 157.874 222.383,155.655 223.952,154.503 
                    224.631 C 153.350 225.310,151.824 226.493,151.111 227.259 L 149.815 228.653 151.296 227.541 C 152.111 226.929,153.924 225.807,155.324 225.047 C 156.725 224.288,158.624 223.092,159.544 222.389 C 161.653 
                    220.779,161.492 220.762,164.904 222.948 C 168.663 225.357,169.799 225.888,167.963 224.380 C 166.121 222.866,164.464 221.156,163.594 219.872 C 162.702 218.555,162.684 218.561,159.434 221.143 M185.513 
                    225.093 C 185.397 225.845,185.301 226.313,184.734 228.889 C 183.701 233.577,183.633 235.006,184.409 235.709 C 185.050 236.289,185.078 236.275,184.922 235.456 C 184.831 234.979,184.896 234.050,185.066 
                    233.392 L 185.377 232.194 186.770 234.060 C 188.581 236.486,189.288 236.528,188.657 234.171 C 188.534 233.715,188.311 233.418,188.160 233.511 C 188.009 233.605,187.535 233.308,187.106 232.852 L 186.327 
                    232.022 187.340 231.341 C 187.898 230.967,188.539 230.267,188.766 229.787 L 189.178 228.913 187.830 229.490 C 187.088 229.808,186.254 230.161,185.976 230.275 C 185.159 230.610,185.859 228.895,187.136 
                    227.432 L 188.245 226.160 187.415 225.487 C 186.565 224.799,185.589 224.596,185.513 225.093 M177.792 225.996 C 176.193 227.712,177.036 229.811,179.907 231.262 C 182.112 232.377,180.936 234.518,178.043 
                    234.658 C 176.031 234.756,175.879 235.691,177.848 235.854 C 182.205 236.217,184.286 232.189,180.586 230.553 C 178.307 229.546,177.703 228.513,178.728 227.381 C 179.578 226.442,179.860 226.477,180.956 
                    227.660 C 182.223 229.027,183.212 228.233,182.563 226.369 C 182.026 224.830,179.092 224.600,177.792 225.996 M201.667 225.749 C 200.648 226.011,199.208 226.241,198.466 226.261 C 197.330 226.291,197.079 
                    226.451,196.872 227.276 C 196.519 228.681,197.131 229.138,198.638 228.593 C 199.314 228.348,200.042 228.148,200.255 228.148 C 200.716 228.148,200.453 233.175,199.939 234.167 C 199.553 234.912,200.203 
                    236.444,200.759 236.100 C 200.953 235.980,201.112 235.184,201.114 234.330 C 201.115 233.476,201.223 231.653,201.352 230.278 L 201.589 227.778 202.971 227.778 C 204.430 227.778,204.948 227.383,205.282 
                    226.019 C 205.524 225.032,204.697 224.970,201.667 225.749 M190.439 226.952 C 190.585 228.212,191.811 228.570,192.106 227.440 C 192.218 227.014,192.456 226.667,192.636 226.667 C 193.669 226.667,192.737 
                    226.037,191.643 225.996 C 190.416 225.950,190.331 226.017,190.439 226.952 M207.410 230.367 C 206.197 232.071,206.015 234.163,206.994 235.142 C 209.273 237.421,213.342 234.637,211.839 231.828 C 211.282 
                    230.786,210.675 230.931,211.157 231.990 C 211.933 233.692,209.728 235.602,208.329 234.440 C 207.073 233.399,208.744 229.951,210.229 230.521 C 210.624 230.673,210.758 230.543,210.672 230.091 C 210.441 
                    228.870,208.350 229.047,207.410 230.367 M213.843 229.802 C 212.609 233.017,213.899 236.642,215.875 235.513 C 216.867 234.947,216.947 234.955,217.392 235.669 C 217.910 236.498,218.193 236.230,218.683 
                    234.444 C 218.877 233.737,218.845 233.650,218.548 234.074 C 217.916 234.978,218.047 232.752,218.701 231.487 C 219.195 230.531,219.192 230.356,218.671 229.835 C 217.825 228.989,217.525 229.369,217.240 
                    231.655 C 217.030 233.331,216.795 233.826,215.903 234.461 C 213.976 235.833,213.580 234.804,214.875 231.794 C 215.548 230.229,215.592 229.880,215.153 229.602 C 214.409 229.131,214.081 229.181,213.843 
                    229.802 M220.562 232.455 C 220.387 234.552,220.432 235.765,220.692 236.026 C 221.209 236.542,221.429 235.910,221.563 233.524 C 221.654 231.898,221.809 231.588,222.856 230.931 C 223.510 230.521,224.052 
                    229.977,224.060 229.722 C 224.077 229.172,222.736 229.097,222.407 229.631 C 222.256 229.874,221.945 229.878,221.502 229.641 C 220.881 229.308,220.805 229.535,220.562 232.455 M190.595 230.215 C 190.127 
                    231.437,190.345 235.926,190.873 235.926 C 191.342 235.926,191.852 233.099,191.852 230.501 C 191.852 229.515,190.943 229.308,190.595 230.215 " stroke="none" fill="#f7f5f5" fillRule="evenodd"/></svg></p>
                </div>
                <div className="my-info">
                    <p>Author: Ali Daeihagh</p>
                    <p>Github: AliseenaD</p>
                </div>
            </div>
        </div>
    );
}